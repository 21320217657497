import { computed } from 'vue'


export const useEnv = () => {
	const isEnv = computed(() => {
		const x = window.location.href
		if(x.includes('localhost')) {
			return 'dev'
		} else if(x.includes('test')) {
			return 'test'
		} else if(x.includes('qa')) {
			return 'staging'
		} else {
			return 'production'
		}
	})

	return {isEnv}
}
