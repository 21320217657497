import {ref} from 'vue'
import Swal from 'sweetalert2'
import {axiosInstance as axios} from '@/plugins/axios.js'


const userSettings = {
	byDomain: ref(false),
	dropOffSetting: ref(),
	emails: ref([]),
	prevent_staff_view_of_public_routes: ref(false),
	staff_details_config: ref([]),
	toSetStaffDetails: ref(false)
}

export const useUserSetting = () => {
	const loading = ref(false)
	const fetchUserSetting = (companyId) => {
		loading.value = true
		axios.get(`/v1/corporates/${companyId}/settings`)
		.then((res) => {
			userSettings.byDomain.value = res.data.enable_staff_addition_by_domain ? res.data.enable_staff_addition_by_domain : false
			userSettings.dropOffSetting.value = res.data.enable_pickup_dropoff_edit ? res.data.enable_pickup_dropoff_edit : false
			userSettings.prevent_staff_view_of_public_routes.value = res.data.prevent_staff_view_of_public_routes ? res.data.prevent_staff_view_of_public_routes : false
			if(res?.data?.staff_details_config?.length) {
				userSettings.staff_details_config.value = res.data.staff_details_config
			} else {
				userSettings.staff_details_config.value = []
			}
			
			if(res?.data?.notification_emails && res?.data?.notification_emails.length) {
				userSettings.emails.value = res.data.notification_emails
			} else {
				userSettings.emails.value = []
			}
			loading.value = false
		})
		.catch((err) => {
			loading.value = false
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		})
	}

	const setDomain = (companyId, val) => {
		loading.value = true
		axios.post(`/v1/corporates/${companyId}/settings`, {
			enable_staff_addition_by_domain: val
		})
		.then((res) => {
			loading.value = false
			if(res.data.success) {
				Swal.fire({ icon: 'success', title: 'Success', text: res?.data?.message || 'Domain set successfully'})
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to set domain'})
			}
		})
		.catch((err) => {
			loading.value = false
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message || 'An error occured'})
		})
	}

	const setStaffToViewPublicRoutes = (companyId, val) => {
		loading.value = true
		axios.post(`/v1/corporates/${companyId}/settings`, {
			prevent_staff_view_of_public_routes: val
		})
		.then((res) => {
			loading.value = false
			if(res.data.success) {
				Swal.fire({ icon: 'success', title: 'Success', text: res?.data?.message || 'Domain set successfully'})
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to set domain'})
			}
		})
		.catch((err) => {
			loading.value = false
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message || 'An error occured'})
		})
	}

	const setStaffDetailsConfig = (companyId) => {
		loading.value = true
		axios.post(`/v1/corporates/${companyId}/settings`, {
			staff_details_config: removeSpecificObjFromArr(userSettings.staff_details_config.value)
		})
		.then((res) => {
			loading.value = false
			if(res.data.success) {
				Swal.fire({ icon: 'success', title: 'Success', text: res?.data?.message || 'Staff details configuration set successfully'})
				userSettings.toSetStaffDetails.value = false
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to set staff details configuration'})
			}
		})
		.catch((err) => {
			loading.value = false
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		})
	}

	const setEmails = () => {
		loading.value = true
		axios.post(`/v1/corporates/${companyId}/settings`, {
			notification_emails: [...userSettings.emails.value]
		})
		.then((res) => {
			if(res?.data?.success) {
				Swal.fire({ icon: 'success', title: 'Success', text: res?.data?.message || 'Email added to notification list successfully'})
				useUserSetting().fetchUserSetting()
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to add email to notifiation list'})
			}
			loading.value = false
		})
		.catch((err) => {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
			loading.value = false
		})
	}
	return { ...userSettings, loading, fetchUserSetting, setDomain, setEmails, setStaffDetailsConfig, setStaffToViewPublicRoutes }
}


export const useDropoffSetting = () => {
	const is_loading = ref(false)
	const setDropOff = (companyId) => {
		is_loading.value = true
		// makeFetchWithAuthAndBody('POST', `v1/corporates/${companyId}/settings`, {
		// 	enable_pickup_dropoff_edit: userSettings.dropOffSetting.value
		// })
		axios.post(`/v1/corporates/${companyId}/settings`, {
			enable_pickup_dropoff_edit: userSettings.dropOffSetting.value
		})
		.then((res) => {
			if(res.data.success) {
				Swal.fire({ icon: 'success', title: 'Success', text: res?.data?.message || 'Drop off Setting updated successfully'})
				useModal().closeModal()
				useUserSetting().fetchUserSetting()
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res?.data?.message || 'Unable to update drop off setting'})
			}
			is_loading.value = false
		})
		.catch((err) => {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
			is_loading.value = false
		})
	}

	return { ...userSettings, setDropOff, is_loading }
}

const removeSpecificObjFromArr = (arr) => {
	const labelsToRemove = ['fname', 'email', 'lname']
	const filteredObjects = arr.filter((obj) => !labelsToRemove.includes(obj.field_name))
	return filteredObjects
}
