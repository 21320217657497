<template>
	<div class="w-full">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
	
		<div v-else class="w-full flex flex-col gap-[30px] ">
			<div class="flex items-center gap-4 justify-between border-b pb-3">
				<div class="flex flex-col gap-1">
					<h3 class="font-medium text-lg text-[#131615]">Staff settings</h3>
					<p class="text-sm text-[#737876]">Corporates should be able to configure different settings for their staff.</p>
				</div>
				<button class="btn bg-black py-1 px-2 rounded text-white text-sm">Reset to default</button>
			</div>
			
			<div class="flex flex-col gap-[24px] max-w-[450px]">
				<div class="flex flex-col gap-1">
					<p class="text-[#09090F] text-lg font-medium">Staff profile update link</p>
					<p class="text-sm text-[#737876]">Share this link to your staff whenever you need them to update their profiles</p>
					<div class="flex items-center justify-between gap-4 p-2 rounded-lg border">
						<p class="text-black text-xs font-medium whitespace-nowrap w-[70%] text-left overflow-hidden text-ellipsis">{{ updateLink }}</p>
						<button class="text-green-700 btn py-1 font-medium text-sm" @click="copyData">Copy link</button>
					</div>
				</div>

				<div class="flex flex-col gap-2">
					<div class="flex items-center gap-4 justify-between">
						<div class="flex flex-col gap-1">
							<p class="text-[#09090F] text-lg font-medium">Staff fields configuration</p>
							<p class="text-sm text-[#737876]">Configure fields that should be made compulsory or visible during the staff onboarding process</p>
						</div>
						<div class="flex-grow">
							<button v-if="!toSetStaffDetails" class="btn py-2 bg-green-500 text-[#000005] font-medium" @click="toSetStaffDetails = true">Update</button>
							<div v-else class="flex items-center gap-2">
								<button class="btn" @click="toSetStaffDetails = false">Cancel</button>
								<button class="btn py-2 bg-green-500 text-white font-medium" @click="setStaffDetailsConfig(companyId)">Proceed</button>
							</div>
						</div>
						
						
					</div>
					<div class="relative">
						<div v-if="!toSetStaffDetails" class="table_overlay absolute top-0 left-0 w-full h-full z-20"></div>
						<table class="w-full">
							<thead>
								<tr class="text-left text-sm text-[#6E717C] font-normal border-b">
									<th class="py-3 px-2  w-[33%]">Fields</th>
									<th class="py-3 px-2 w-[34%] border-l">Compulsory</th>
									<th class="py-3 px-2 w-[33%] border-l">Visible</th>
								</tr>
							</thead>
							<tbody v-if="staff_details_config.length">
								<tr v-for="n in staff_details_config" :key="n.label" class="border-b">
									<td class="text-gray7 font-medium text-sm py-3 px-2">{{ n.label }}</td>
									<td class="text-center border-l py-3 px-2">
										<input type="checkbox" class="custom-checkbox" @change="checkField(n, 'is_compulsory')" v-model="n.is_compulsory" />
									</td>
									<td class="text-center border-l py-3 px-2">
										<input type="checkbox" class="custom-checkbox" @change="checkField(n, 'is_visible')" v-model="n.is_visible" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					
				</div>

				<div class="flex items-center justify-between gap-4">
					<div class="flex flex-col gap-1">
						<p class="text-[#09090F] text-lg font-medium">Company domain</p>
						<p class="text-sm text-[#737876]">Add any user that joins with the company domain</p>
					</div>
					<VueToggles width="50" checkedBg="#15CD70" :value="byDomain" @click="toggleDomain" />
				</div>

				<div class="flex items-center justify-between gap-4">
					<div class="flex flex-col gap-1 flex-1">
						<p class="text-[#09090F] text-lg font-medium">Your staff can see routes that are not assigned to your company</p>
						<p class="text-sm text-[#737876]">Staffs will only see routes that are assigned to your company when they use the shuttlers mobile and web apps.</p>
					</div>
					<VueToggles width="50" checkedBg="#15CD70" :value="prevent_staff_view_of_public_routes" 
						@click="togglePreventStaff" 
					/>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script setup > 
import { ref, watch, defineProps, computed} from 'vue'
import { useUserSetting } from '../../composables/settings'
import router from '@/router'
import { useClipboard } from '@vueuse/core'
import { useEnv } from '@/composables/core/env'
import {useBranch } from '@/composables/backend/companies/branch'
import {useShifts } from '@/composables/backend/companies/workshift'
import {useGroup } from '@/composables/backend/companies/groups'
import Swal from 'sweetalert2'
import VueToggles from 'vue-toggles'


const props = defineProps(['companyId'])
const { isEnv } = useEnv()
const {groups, fetchGroup} = useGroup()
const {shifts, fetchWorkShifts} = useShifts()
const { branches, fetchBranches } = useBranch()
const { loading, byDomain, setDomain, prevent_staff_view_of_public_routes, 
	staff_details_config, toSetStaffDetails, setStaffDetailsConfig, fetchUserSetting, setStaffToViewPublicRoutes } = useUserSetting()
// const updateLink = ref(`business/invitation?corporate_id=${props.companyId}&mode=update`)
const source = ref('')
const { copy, copied, isSupported } = useClipboard({ source })

const updateLink = computed(() => {
	if(isEnv.value == 'dev') {
		return `https://test.business.shuttlers.africa/business/invitation?corporate_id=${props.companyId}&mode=update`
	} else if(isEnv.value == 'test') {
		return `https://test.business.shuttlers.africa/business/invitation?corporate_id=${props.companyId}&mode=update`
	} else if(isEnv.value == 'staging') {
		return `https://qa.business.shuttlers.africa/business/invitation?corporate_id=${props.companyId}&mode=update`
	} else {
		return `https://business.shuttlers.africa/business/invitation?corporate_id=${props.companyId}&mode=update`
	}
})

const checkField = (obj, key) => {
	const toCheck = ['corporate_office_branch_id', 'group_id', 'corporate_workshift_id']

	// whenever is visible checkbox is turned off, it should turn off compulsory automatically(whether its on or off)
	if(key === 'is_visible') {
		if(obj.is_visible == false) obj.is_compulsory = false
	}

	// make is visible true for other fields that does have requirements
	if(!toCheck.includes(obj.field_name) && obj.is_compulsory == true) obj.is_visible = true

	if(toCheck.includes(obj.field_name)) {
		if(obj[key] === true) {
			if(obj.field_name == 'corporate_office_branch_id') handleBranch(obj, key)
			if(obj.field_name == 'corporate_workshift_id') handleShift(obj, key)
			if(obj.field_name == 'group_id') handleGroup(obj, key)
		}
	}
}

const handleBranch = (obj,key ) => {
	if(!branches.value.length) {
		Swal.fire({ icon: 'error', title: 'Error', text: 'This company has not created any office branch'})
		setTimeout(() => { obj[key] = false },500)
		return
	}
	if(key == 'is_compulsory') obj.is_visible = true
}

const handleShift = (obj,key ) => {
	if(!shifts.value.length) {
		Swal.fire({ icon: 'error', title: 'Error', text: 'This company has not created any work shift'})
		setTimeout(() => { obj[key] = false },500)
		return
	}
	if(key == 'is_compulsory') obj.is_visible = true
}

const handleGroup = (obj,key ) => {
	if(!groups.value.length) {
		Swal.fire({ icon: 'error', title: 'Error', text: 'This company has not created any group'})
		setTimeout(() => { obj[key] = false },500)
		return
	}
	if(key == 'is_compulsory') obj.is_visible = true
}

const copyData = () => {
	source.value = updateLink.value 
	if(isSupported) {
		copy(source.value)
		Swal.fire({ icon: 'success', title: 'Copied', text: 'Successfully copied to clipboard'})
	} else {
		Swal.fire({ icon: 'error', title: 'Error', text: 'Looks like your device cannot copy to clipboard'})
	}
}

const toggleDomain = () => {
	byDomain.value = !byDomain.value
	setDomain(props.companyId,byDomain.value)
}

const togglePreventStaff = () => {
	prevent_staff_view_of_public_routes.value = !prevent_staff_view_of_public_routes.value
	setStaffToViewPublicRoutes(props.companyId, prevent_staff_view_of_public_routes.value)
}

fetchGroup(props.companyId)
fetchBranches(props.companyId)
fetchWorkShifts(props.companyId)
fetchUserSetting(props.companyId)
toSetStaffDetails.value = false
</script>

<style scoped>
h3,p{
	margin: 0;
}
.table_overlay{
	background-color: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(2px);
}
</style>